import { Box, Flex } from 'rebass';
import { FaExclamation } from 'react-icons/fa';
import { t } from '@lingui/macro';

const style = {
  width: '32px',
  height: '30px',
  fontSize: 18,
  p: 1,
  color: 'white',
  textAlign: 'center',
};

const ValueWarning = ({ value, min, max }) => {
  if (value === '' || value === null || (value != null && !/^[0-9]+([,.][0-9]+)?$/.test(value))) {
    return (
      <Flex bg="red" sx={style} fontSize={16} alignItems="center" justifyContent="center" title={t`Invalid input!`}>
        <FaExclamation />
      </Flex>
    );
  }

  if (max && value > max) {
    return (
      <Box bg="orange" sx={style} title={t`Higher value than reference!`}>
        <ArrowUpIcon />
      </Box>
    );
  }

  if (min && value < min) {
    return (
      <Box bg="orange" sx={style} title={t`Lower value than reference!`}>
        <ArrowDownIcon />
      </Box>
    );
  }

  return null;
};

export default ValueWarning;

function ArrowUpIcon() {
  return (
    <svg viewBox="0 0 24 24" width={23} height={23}>
      <path d="M15,20H9V12H4.16L12,4.16L19.84,12H15V20Z" stroke={style.color} fill={style.color} />
    </svg>
  );
}

function ArrowDownIcon() {
  return (
    <svg viewBox="0 0 24 24" width={23} height={23}>
      <path d="M9,4H15V12H19.84L12,19.84L4.16,12H9V4Z" stroke={style.color} fill={style.color} />
    </svg>
  );
}
